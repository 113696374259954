.trialContainer {
    background-image: url('@/images/trial-bg.jpg');
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    font-family: Roboto, sans-serif;
}

.trialWrapper {
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    overflow-y: auto;
}

.trialContainerRow {
    width: 100%;
}

.trialTitleWrapper {
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
}

@keyframes slide-in-out {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.trialTitleLarge {
    font-size: 54px;
    color: #ffffff;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: Ubuntu, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 4px;
    text-align: left;
    line-height: 58px;
    animation: slide-in-out .5s;
}
.trialTitleRegular {
    text-align: left;
    color: #ffffff;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 1px;
    margin-bottom: 20px;
    animation: slide-in-out .8s;
}

.trialForm {
    background-color: #ffffff;
    width: 80%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 50px;
    box-shadow: 0 0 10px 2px rgb(0, 0, 0, 10%);
    text-align: left;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}

.trialFormTitle {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #54565a;
}

.trialSubmitBtn {
    width: 100%;
    letter-spacing: 1px;
    border-radius: 4px;
    background-color: #195bf1;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    padding: 15px 40px;
    font-size: 16px;
    transition: all .2s;
}

.trialFormItem {
    margin-top: 10px;
}
.trialFormItem label {
    color: #c2c2c2;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.trialFormItemInput {
    width: 100%;
    height: 43px;
    line-height: 43px;
    padding: 4px 20px;
    border: 1px solid #e4e4e4;
    transition: all .2s;
    border-radius: 5px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
    color: #54565a;
}
.trialFormItemInput:hover {
    border: 1px solid #bebebe;
}
.trialFormItemInput:focus {
    border: 1px solid #195bf1;
    box-shadow: 3px 3px 16px 0 rgb(0 0 0 / 20%);
}

.trialSubmitBtn:hover {
    background-color: #0f4acf;
    box-shadow: 0 0 10px 2px rgb(0, 0, 0, .2);
}