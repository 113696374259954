.stepSpinWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background-color: rgba(255, 255, 255, .95);
  border-radius: 4px;

  animation: stepSpinWrapper-slide-in-out .5s;
}

@keyframes stepSpinWrapper-slide-in-out {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.stepSpinSpin {}

.stepSpinSteps {
  width: 80%;
  max-width: 300px;
  letter-spacing: 1px;
}

.stepSpinCloseBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
}

.stepSpinErrorText {
  letter-spacing: 1px;
  color: #ff4d4f;
}