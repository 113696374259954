.header-ui {
  text-align: left;
  padding: 0 24px;
  height: 48px;
  line-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
  background-color: #fff;
  z-index: 1;
}
.sidebar-logo-container {
  display: flex;
  align-items: center;
}
.sidebar-logo-text {
  font-size: 18px;
  font-weight: 700;
  display: inline;
  margin: 0;
  user-select: none;
  letter-spacing: .5px;
  /* color: #333; */
}
.sidebar-logo-img {
  height: 32px;
  margin-right: 10px;
  -webkit-user-drag: none;
  user-select: none;
}

.aside {
  background: #fff;
}
.aside .ant-layout-sider-trigger {
  background: #fff;
  color: #001529;
  height: 45px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.site-layout-background {
  background: #fff;
}

.layout-content {
  overflow-y: auto;
  height: calc(100vh - 48px);
}

.user-dropdown-item .user-dropdown-text {
  margin-left: 5px;
}
