.login-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.text-logo {
  margin-bottom: 39px;
  font-weight: 700;
  text-align: center;
}

.login-wrapper {
  width: 300px;
}

.login-form-button {
  width: 100%;
}
